<template>
	<div>
		<ITCSpinner :loaded="loaded">
			<b-table table-responsive :fields="fields" :items="data">
				<template #cell(Name)="data">
					<router-link :to="{ name: 'site', params: { id: data.item.SiteId } }">{{ data.value }}</router-link>
				</template>
				<template #cell(value)="data">
					{{ data.item.name === 'modcod' ? modcods[data.value - 1].name : data.value }}
				</template>
				<template #cell(alert_time)="data">
					{{ data.value !== '0000-00-00 00:00:00' ? data.value : '' }}
				</template>
				<template #cell(last_alert_sent)="data">
					{{ data.value !== '0000-00-00 00:00:00' ? data.value : '' }}
				</template>
				<template #cell(muted)="data">
					<div v-if="data.value == 1">
						<div>
							Muted by {{ data.item.mutename }} on {{ data.item.mutedate }} (<span class="clickable" @click="showMuteModal(data.item)"> Unmute </span
							>)
						</div>
						<div>
							<strong
								>Muted Until: {{ data.item.muteuntil == '0000-00-00 00:00:00' ? 'Forever' : data.item.muteuntil }} - reason:
								{{ data.item.mutereason }}</strong
							>
						</div>
					</div>
					<div v-else>
						<span class="clickable" @click.prevent="showMuteModal(data.item)">Click to Mute</span>
					</div>
				</template>
				<template #cell(actions)="data">
					<div class="d-flex justify-content-center">
						<div>
							<router-link :to="{ name: 'admineditsite', params: { id: data.item.SiteId } }">
								<span class="btn btn-icon btn-light btn-sm mx-3" v-b-tooltip="'Edit Alert in Admin'">
									<span class="svg-icon svg-icon-md svg-icon-info">
										<inline-svg src="/media/svg/icons/Communication/Write.svg"></inline-svg>
									</span>
								</span>
							</router-link>
						</div>
						<div>
							<span
								class="btn btn-icon btn-light btn-sm mx-3"
								@click="deleteAlert(data.item.id, data.index, data.item.Name)"
								v-b-tooltip="'Delete Emailed Alert'"
							>
								<span class="svg-icon svg-icon-md svg-icon-danger delete-icon">
									<inline-svg src="/media/svg/icons/General/Trash.svg"></inline-svg>
								</span>
							</span>
						</div>
					</div>
				</template>
			</b-table>
		</ITCSpinner>
		<MuteModal :alert="selectedAlert" @hidden="selectedAlert = null" @saved="updateData" />
	</div>
</template>

<script>
import { MODCODS } from '@/helpers.js';

export default {
	name: 'NOCEmailedAlerts',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		MuteModal: () => import('@/view/content/widgets/admin/editsite/NOCAlertsModal.vue'),
	},
	data() {
		return {
			loaded: false,
			fields: [
				{ key: 'Name', label: 'Site' },
				{ key: 'name', label: 'Alert Type' },
				{ key: 'value', label: 'Alert Value' },
				{ key: 'alert_wait' },
				{ key: 'alert_again_wait', label: 'Additional Alert' },
				{ key: 'alert_time' },
				{ key: 'last_alert_sent' },
				{ key: 'muted' },
				{ key: 'actions', thClass: 'text-center' },
			],
			data: null,
			modcods: MODCODS,
			selectedAlert: null,
		};
	},
	methods: {
		showMuteModal(alert) {
			this.selectedAlert = alert;
			this.$bvModal.show('muteModal');
		},
		updateData(data) {
			let i = this.data.findIndex(d => d.id === data.id);
			this.data.splice(i, 1, { ...this.data[i], ...data });
		},
		deleteAlert(id, index, name) {
			this.$bvModal
				.msgBoxConfirm('Delete alert for site ' + name + '?', {
					title: 'Delete',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
					hideHeaderClose: false,
				})
				.then(val => {
					if (val) {
						this.$http.post('nocalerts', { type: 'delete', id: id }).then(resp => {
							if (resp.data.data.type == 1) {
								this.data.splice(index, 1);
							}
						});
					}
				});
		},
	},
	created() {
		this.loaded = false;
		this.$http.get('nocalerts').then(resp => {
			this.data = resp.data.data;
			if(this.$route.params.alertid && !this.$route.params.alertid.startsWith('l')){
				var alert = this.data.find(f => f.id == this.$route.params.alertid);
				if(alert){
					this.showMuteModal(alert);
				}
			}
			this.loaded = true;
		});
	},
};
</script>

<style scoped>
.clickable {
	color: #3699ff;
	cursor: pointer;
}
.clickable:hover {
	color: #0073e9;
}
.delete-icon:hover i {
	color: red;
	cursor: pointer;
}
</style>
